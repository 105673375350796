import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Header = ({css}) => {
    return ( 
       
        <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand href="/">Jose A. Navas</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/Formacion">Formacion</Nav.Link>
            <Nav.Link as={Link} to="/Experiencia">Experiencia</Nav.Link>
            <NavDropdown title="Conocimientos" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/conocimientosBD">Bases de datos</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/conocimientosLenguaje">Lenguajes</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/otrosConocimientos">Otros</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      
     );

     
}
 
export default Header;
    
