import React, { Fragment } from 'react';  
import Header from './Header';
import Footer from './Footer';
import foto from '../images/foto_perfil.jpg';

const ConocimientosBD = () => {
    return (  
        <Fragment>
            <Header/>
           
            <div className="wrapper">
        <div className="sidebar-wrapper">
            <div className="profile-container">
                <img className="profile rounded-circle imgYo ml-5" src={foto} alt="" />
                <h1 className="name">Jose Navas</h1>
                <h3 className="tagline">Full Stack Developer</h3>
            </div>
            
            <div className="contact-container container-block">
                <ul className="list-unstyled contact-list">
                    <li className="email"><i className="fa fa-envelope"></i><a href="mailto: koeman1976sf@gmail.com">koeman1976sf@gmail.com</a></li>
                    <li className="website"><i className="fa fa-globe"></i><a href="http:www.joseanavas.com" 
                        target="_blank" rel="noopener noreferrer">joseanavas.com</a></li>
                    <li className="linkedin"><i className="fa fa-linkedin"></i><a href="https://www.linkedin.com/in/jose-antonio-navas-ruiz-25694859/" 
                        target="_blank" rel="noopener noreferrer">linkedin</a></li>
                    <li className="twitter"><i className="fa fa-twitter"></i><a href="https://twitter.com/koeman1976?s=09" 
                    target="_blank" rel="noopener noreferrer">@koeman1976</a></li>
                </ul>
            </div>
            <div className="education-container container-block">
                <h2 className="container-block-title">DIRECCIÓN</h2>
                <div className="item">
                    <h5 className="meta">San Fernando de Henares </h5>
	                    <div className="time">28830 MADRID (ESPAÑA)</div>
                </div>
            </div>
            
            <div className="languages-container container-block">
                <h2 className="container-block-title">IDIOMAS</h2>
                <ul className="list-unstyled interests-list">
                    <li>Español <span className="lang-desc">(Native)</span></li>
                    <li>Ingles <span className="lang-desc">(Professional)</span></li>
                </ul>
            </div>
            
           
            
        </div>
        
        <div className="main-wrapper">
            
            <section className="section experiences-section">
                <h2 className="section-title"><span className="icon-holder"><i className="fa fa-book"></i></span>CONOCIMIENTOS BD</h2>
                
                <div className="item">
                    <div className="meta">
                        <div className="upper-row">
                            <h3 className="job-title">SQL SERVER</h3>
                        </div>
                        <div className="company">Experiencia alta </div>
                    </div>
                    <div className="details">
                    <p>
                        - Experiencia desde 2005 con este sistema gestor de bases de datos. <br />
                        - Diseño avanzado de bases de datos a partir de diagramas UML o ER. <br />
                        - Programación en BD, triggers, procedimientos, indices, etc. <br />
                        - Programación avanzada en Transact-SQL (T-SQL) <br />
                        - Gestión de bases de datos (DBA - SQL) <br />

                    </p>
                    </div>
                </div>
                <div className="item">
                    <div className="meta">
                        <div className="upper-row">
                            <h3 className="job-title">CLOUD</h3>
                        </div>
                        <div className="company">Experiencia media </div>
                    </div>
                    <div className="details">
                    <p>
                        - Microsoft Azure. <br />
                        - Firebase y Firestore. <br />
                        - MongoDB <br />
                        - DataCMS <br />
                    </p>
                    </div>
                </div>
                <div className="item">
                    <div className="meta">
                        <div className="upper-row">
                            <h3 className="job-title">OTRAS</h3>
                        </div>
                        <div className="company">Experiencia alta </div>
                    </div>
                    <div className="details">
                   
                   <p>
                       - SQLite para movilidad. <br />
                   </p>
                   </div>
                    <div className="meta">
                        <div className="upper-row">                        
                        </div>
                        <div className="company">Experiencia media </div>
                    </div>
                    <div className="details">
                   
                    <p>
                        - Oracle. <br />
                        - MySQL. <br />
                    </p>
                    </div>
                </div>
                <br />
            </section>
        </div>
    </div>
        
            <Footer/>
        </Fragment>
    );
}
 
export default ConocimientosBD;