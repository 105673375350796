import React, { Fragment } from 'react';  
import Header from './Header';
import Footer from './Footer';
import foto from '../images/foto_perfil.jpg';

const Experiencia = () => {
    
    let css = 'Experiencia';
    return (  
        <Fragment>
            <Header 
                css = {css}
            />

            <div className="wrapper">
        <div className="sidebar-wrapper">
            <div className="profile-container">
                <img className="profile rounded-circle imgYo ml-5" src={foto} alt="" />
                <h1 className="name">Jose Navas</h1>
                <h3 className="tagline">Full Stack Developer</h3>
            </div>
            
            <div className="contact-container container-block">
                <ul className="list-unstyled contact-list">
                    <li className="email"><i className="fa fa-envelope"></i><a href="mailto: koeman1976sf@gmail.com">koeman1976sf@gmail.com</a></li>
                    <li className="website"><i className="fa fa-globe"></i><a href="http:www.joseanavas.com" 
                        target="_blank" rel="noopener noreferrer">joseanavas.com</a></li>
                    <li className="linkedin"><i className="fa fa-linkedin"></i><a href="https://www.linkedin.com/in/jose-antonio-navas-ruiz-25694859/" 
                        target="_blank" rel="noopener noreferrer">linkedin</a></li>
                    <li className="twitter"><i className="fa fa-twitter"></i><a href="https://twitter.com/koeman1976?s=09" 
                    target="_blank" rel="noopener noreferrer">@koeman1976</a></li>
                </ul>
            </div>
            <div className="education-container container-block">
                <h2 className="container-block-title">DIRECCIÓN</h2>
                <div className="item">
                    <h5 className="meta">San Fernando de Henares </h5>
	                    <div className="time">28830 MADRID (ESPAÑA)</div>
                </div>
            </div>
            
            <div className="languages-container container-block">
                <h2 className="container-block-title">IDIOMAS</h2>
                <ul className="list-unstyled interests-list">
                    <li>Español <span className="lang-desc">(Native)</span></li>
                    <li>Ingles <span className="lang-desc">(Professional)</span></li>
                </ul>
            </div>
            
           
            
        </div>
        
        <div className="main-wrapper">
            
            <section className="section summary-section">
                <h2 className="section-title"><span className="icon-holder"><i className="fa fa-user"></i></span>Perfil Profesional</h2>
                <div className="summary">
                    <p>
                        Informático con mas de 13 años de experiencia desarrollando aplicaciones, capacitado
                        para acometer todo tipo de desarrollos para el entorno que se requiera.<br/>
                        Siempre en formacion continua para mejorar las capacidades y las aptitudes, de esta
                        profesión tan cambiante y apasionante, con retos practicamente diarios.<br/>
                        Me gusta trabajar en equipo y compartir las enseñanzas y aprendizajes con
                        los compañeros.


                    </p>
                </div>
            </section>
            
            <section className="section experiences-section">
                <h2 className="section-title"><span className="icon-holder"><i className="fa fa-briefcase"></i></span>EXPERIENCIA PROFESIONAL</h2>
                
                <div className="item">
                    <div className="meta">
                        <div className="upper-row">
                            <h3 className="job-title">Lead Developer</h3>
                            <div className="time">Enero 2007 - Actualidad</div>
                        </div>
                        <div className="company">WGM S.L.</div>
                    </div>
                    <div className="details">
                        <p> Responsable del equipo de desarrollo para la creacion de Aplicaciones Webs y 
                            aplicaciones moviles para la gestión y mantenimiento de todo tipo de empresas e infraestructuras.<br/>
                            Especialista en Sql Server y Reporting Services, como gestores de datos e informes respectivamente.<br/>
                            Responsable IT de los recursos contratados en Azure.<br/>
                            Actualmente desarrollando en React, Xamarin y .Net Core 3.0.
                        </p>  
                    </div>
                </div>
                
                <div className="item">
                    <div className="meta">
                        <div className="upper-row">
                            <h3 className="job-title">Responsable de turno</h3>
                            <div className="time">Marzo 1998 - Enero 2007</div>
                        </div>
                        <div className="company">Inforsistem </div>
                    </div>
                    <div className="details">
                        <p>Responsable de la gestion de recursos y personal, para empresa encargada de la impresion 
                           y ensobrado de facturas a clientes de grandes entidades.</p>  
                        
                    </div>
                </div>
                
            </section>
            
            
            
            
        </div>
    </div>
        
            <Footer/>
        </Fragment>
    );
}
 
export default Experiencia;