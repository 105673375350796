import React from 'react';

const ImagenMain = () => {
    return ( 
      
       <div className="bg"></div>
      
       
     );
}
 
export default ImagenMain;