import React, { Fragment } from 'react';

const About = () => {
    return (
        <Fragment>
            <div className="row divMain">
            <div className="col-md-0.5 mt-5"></div>

               
                <div className="col-md-8 mb-3 pr-5">
                    <div>
                    <p className="parrafoAbout">
                        Con mas de 13 años de experiencia en el desarrollo de aplicaciones informáticas, 
                        he sido capaz de adaptarme a los distintos cambios de tecnologías que se han ido produciendo
                        a lo largo de este tiempo. <br /><br />
                        Cuento con una gran capacidad de análisis de la información y experiencia en la gestión de equipos,
                        lo cual me ha llevado a ser el responsable informático de la empresa donde trabajo. <br /><br />
                        La clave de todo está en querer seguir aprendiendo cosas nuevas todos los días y que disfrutes
                        con todo lo que haces. 

                    </p>                   
                    </div>
                </div>               
            </div>
        </Fragment>
      );
}
 
export default About;
