import React, { Fragment } from 'react';  
import Header from './Header';
import ImagenMain from './ImagenMain';
import Informacion from './Informacion';
import Footer from './Footer';
import About from './About';

const Main = () => {
    return (  
        <Fragment>
            <ImagenMain />
            <Header />
            
            <h1 className="h1Main">Desarrollador informático</h1>
            <hr className="hrUnderline"/>
            <About/>
            <h1 className="h1Main">Resumen de habilidades o skills</h1>
            <hr className="hrUnderline"/>
            <div className="row divMain">
                <Informacion />
               
            </div>
           <Footer/>
        </Fragment>
        

        
    );
}
 
export default Main;