import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

    let year = new Date();
    let yyyy = year.getFullYear();
    return ( 
        <Fragment>
           <footer role="contentinfo">
                <div className="footer-middle">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                            
                                <div className="footer-pad">
                                    <h2>Acerca de la Web</h2>
                                    <ul className="list-unstyled">
                                        <li></li>
                                        <li><p className="parrafoFooter">Web curriculum personal desarrollada con React hooks y diseñada con Bootstrap 4.</p></li>
                                    </ul>
                                </div>
                            </div>
                        <div className="col-md-4 col-sm-6">
                        
                            <div className="footer-pad">
                                <h2>Enlaces</h2>
                                <ul className="list-unstyled">
                                    <li></li>
                                    <li> <Link to={'/Formacion'}>
                                            Formacion
                                        </Link></li>
                                    <li> <Link to={'/Experiencia'}>
                                        Experiencia
                                    </Link></li>
                                    <li> <Link to={'/ConocimientosBD'}>
                                        Conocimientos BD
                                    </Link></li>
                                    <li> <Link to={'/ConocimientosLenguaje'}>
                                        Conocimientos Lenguajes
                                    </Link></li>
                                    <li> <Link to={'/OtrosConocimientos'}>
                                        Otros Conocimientos
                                    </Link></li>
                                    
                                </ul>
                            </div>
                        </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="footer-pad">
                                    <h2>Sigueme</h2>
                                    <ul className="social-network social-circle">
                                    <li></li>
                                    <li><a href="https://www.facebook.com/kuman33" rel="noopener noreferrer" target="_blank" className="icoFacebook" title="Facebook"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/jose-antonio-navas-ruiz-25694859/" rel="noopener noreferrer" target="_blank" className="icoLinkedin" title="Linkedin"><i className="fa fa-linkedin"></i></a></li>
                                    <li> </li>
                                    </ul>			
                                </div>	
                                < br />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 copy">
                                <p className="text-center">&copy; Copyright {yyyy} - Jose A. Navas.  All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </Fragment>


     );
}
 
export default Footer;