import React, { Fragment } from 'react';  
import Header from './Header';
import Footer from './Footer';
import foto from '../images/foto_perfil.jpg';

const Formacion = () => {
    
    let css = 'Formacion';
    return (  
        <Fragment>
            <Header 
                css = {css}
            />

            <div className="wrapper">
        <div className="sidebar-wrapper">
            <div className="profile-container">
                <img className="profile rounded-circle imgYo ml-5" src={foto} alt="" />
                <h1 className="name">Jose Navas</h1>
                <h3 className="tagline">Full Stack Developer</h3>
            </div>
            
            <div className="contact-container container-block">
                <ul className="list-unstyled contact-list">
                    <li className="email"><i className="fa fa-envelope"></i><a href="mailto: koeman1976sf@gmail.com">koeman1976sf@gmail.com</a></li>
                    <li className="website"><i className="fa fa-globe"></i><a href="http:www.joseanavas.com" 
                        target="_blank" rel="noopener noreferrer">joseanavas.com</a></li>
                    <li className="linkedin"><i className="fa fa-linkedin"></i><a href="https://www.linkedin.com/in/jose-antonio-navas-ruiz-25694859/" 
                        target="_blank" rel="noopener noreferrer">linkedin</a></li>
                    <li className="twitter"><i className="fa fa-twitter"></i><a href="https://twitter.com/koeman1976?s=09" 
                    target="_blank" rel="noopener noreferrer">@koeman1976</a></li>
                </ul>
            </div>
            <div className="education-container container-block">
                <h2 className="container-block-title">DIRECCIÓN</h2>
                <div className="item">
                    <h5 className="meta">San Fernando de Henares </h5>
	                    <div className="time">28830 MADRID (ESPAÑA)</div>
                </div>
            </div>
            
            <div className="languages-container container-block">
                <h2 className="container-block-title">IDIOMAS</h2>
                <ul className="list-unstyled interests-list">
                    <li>Español <span className="lang-desc">(Native)</span></li>
                    <li>Ingles <span className="lang-desc">(Professional)</span></li>
                </ul>
            </div>
            
           
            
        </div>
        
        <div className="main-wrapper">
            
            <section className="section experiences-section">
                <h2 className="section-title"><span className="icon-holder"><i className="fa fa-book"></i></span>FORMACIÓN</h2>
                
                <div className="item">
                    <div className="meta">
                        <div className="upper-row">
                            <h3 className="job-title">Curso React Hooks</h3>
                            <div className="time">Marzo 2020</div>
                        </div>
                        <div className="company">Udemy.com</div>
                    </div>
                    <div className="details">
                        <p> - Curso online de React de 34 horas de videos, incluye 15 apps de ejemplo.<br />
                        Incluye React Hooks, Gatsby y GraphQL, Firestore, Redux, Context, MERN, Next.js, 
                        Styled Components, Custom Hooks como parte de la formación del curso. 
                        </p>  
                    </div>
                </div>
                
                <div className="item">
                    <div className="meta">
                        <div className="upper-row">
                            <h3 className="job-title">- MCTS (Microsoft Certified Technology Specialist)</h3>
                            <div className="time">Junio 2007</div>
                        </div>
                        <div className="company">Microsoft </div>
                    </div>
                    <div className="details">
                        <p> - Certificado por Microsoft para desarrollo de aplicaciones Web y aplicaciones Windows.</p>  
                        
                    </div>
                </div>
                <div className="item">
                    <div className="meta">
                        <div className="upper-row">
                            <h3 className="job-title">- Master .Net</h3>
                            <div className="time">Octubre 2006 – Junio 2007</div>
                        </div>
                        <div className="company">Alhambra Eidos</div>
                    </div>
                    <div className="details">
                        <p>- Master en Desarrollo de Aplicaciones informáticas en .Net (173 horas) </p>  
                        
                    </div>
                </div>
                <div className="item">
                    <div className="meta">
                        <div className="upper-row">
                            <h3 className="job-title">- Curso de Páginas Web</h3>
                            <div className="time">Enero–Abril 2006</div>
                        </div>
                        <div className="company">Universidad Politécnica de Madrid</div>
                    </div>
                    <div className="details">
                        <p>- Curso de Páginas Web con JavaScript y DHTML en la
                           modalidad de tele formación impartido por la Universidad 
                           Politécnica de Madrid (153 horas)  </p>  
                        
                    </div>
                </div>
                <div className="item">
                    <div className="meta">
                        <div className="upper-row">
                            <h3 className="job-title">- Técnico especialista, Infomatica de gestión.</h3>
                            <div className="time">2002 – 2006</div>
                        </div>
                        <div className="company">E.S.I. Escuela de sistemas informáticos</div>
                    </div>
                    <div className="details">
                        <p> </p>  
                        
                    </div>
                </div>
                <div className="item">
                    <div className="meta">
                        <div className="upper-row">
                            <h3 className="job-title">-  Técnico Especialista en Electrónica</h3>
                            <div className="time">1997</div>
                        </div>
                        <div className="company">I.F.P. Barajas</div>
                    </div>
                    <div className="details">
                        <p>- Técnico especialista de grado superior en electrónica industrial. </p>  
                        
                    </div>
                </div>
                
            </section>
            
            
            
            
        </div>
    </div>
        
            <Footer/>
        </Fragment>
    );
}
 
export default Formacion;