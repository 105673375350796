import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
const Informacion = () => {

           return ( 
            <Fragment>        
                <div className="col-md-3 mb-3 mt-5 pr-5">
                    <div className="card">
                    
                        <h2 className="card-header">Conocimientos BD</h2>
                        <p className="parrafoInfo">
                            El 100% de las aplicaciones informaticas requieren almacenar los datos
                            para trabajar con ellos. Es fundamental conocer tanto los sistemas de 
                            almacenamiento tradicionales como Sql Server, Oracle, etc. como las
                            bases de datos online tales como Firebase, MongoDb y similares.
                            Las grandes empresas de alojamiento ofrecen soluciones en la nube para alojar los
                            datos empresariales y es imprescindible conocer plataformas como Azure,
                            Amazon Web Services, Google Cloud entre otras.
                        </p>
                        <div className="card-body">
                        <Link to={'/conocimientosBD'}> 
                            <button
                                type="button"
                                className="btn btn-block btn-primary btnVer"
                            >
                                mis conocimientos en bd
                            </button>
                        </Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-3 mt-5 pr-5">
                    <div className="card">
                        <h2 className="card-header">Conocimientos Lenguajes</h2>
                        <p className="parrafoInfo">
                            Cada poco tiempo nace un nuevo lenguaje, un nuevo framework, una nueva 
                            versión de cada herramienta o libreria de desarrollo. Es muy importante
                            estar al dia de todos estos cambios y dedicar una buena parte de tu 
                            tiempo a formación. Pero casi lo mas importante de todo, es poder adaptarse a las nuevas
                            tecnologias y no quedarse estancado en lo que uno ya domina. <br />
                            Conocer distintas plataformas, movil, web y escritorio con distintas herramientas. <br />
                            "A programar se aprende programando."

                        </p>
                        <div className="card-body">
                            <Link to={'/conocimientosLenguaje'}> 
                                <button
                                    type="button"
                                    className="btn btn-block btn-primary btnVer"
                                >
                                    que lenguajes domino
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-3 mt-5 pr-5">
                    <div className="card">
                        <h2 className="card-header">Otros Conocimientos</h2>
                        <p className="parrafoInfo">
                            Avanzamos hacia un futuro donde al desarrollador se le 
                            exigen cada vez mas aptitudes y donde se valora muchisimo
                            la polivalencia dentro de un grupo de desarrollo.
                            Poder asumir diferentes roles dentro de un proyecto, hace
                            imprescindible a esa persona y que crezca dentro de la estructura
                            de la empresa.<br />
                            Dominar distintas tecnologias de desarrollo, 
                            control de proyectos y versiones, etc, son ejemplos de otros conocimientos
                            tan importantes como el propio lenguaje.

                             
                        </p>
                        <div className="card-body">
                        <Link to={'/otrosConocimientos'}> 
                            <button
                                type="button"
                                className="btn btn-block btn-primary btnVer"
                            >
                                Mis otros conocimientos
                            </button>
                        </Link>
                        </div>
                    </div>
                </div>
        </Fragment>


    );
}
 
export default Informacion;