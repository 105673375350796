import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Formacion from './components/Formacion';
import Experiencia from './components/Experiencia';
import conocimientosBD from './components/ConocimientosBD';
import conocimientosLenguaje from './components/ConocimientosLenguaje';
import otrosConocimientos from './components/OtrosConocimientos';
import Main from './components/Main';
import DynamicScrollToTop from './routes/DynamicScrollToTop';

function App() {

  

  return (
     
      <Router>
        <DynamicScrollToTop />
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/formacion" component={Formacion} />
          <Route exact path="/experiencia" component={Experiencia}/>
          <Route exact path="/conocimientosBD" component={conocimientosBD}/>
          <Route exact path="/conocimientosLenguaje" component={conocimientosLenguaje}/>
          <Route exact path="/otrosConocimientos" component={otrosConocimientos}/>
        </Switch>
      </Router>
  );
}

export default App;
